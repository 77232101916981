import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import Cv from 'components/cv';

function CvPage() {
  return (
    <Layout>
      <Seo title="Pintarnya Kerja | CV Pintar" />
      <Cv />
    </Layout>
  );
}

export default CvPage;